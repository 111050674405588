import React from "react";

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';

function ContractUpdate() {

    return (
    <>
        <SEO title="Standard Form Contract changes" 
            description="Standard Contract changes, effective 1 July 2024"
            titleBespoke="true"
            meta={[{name:"robots", content:'noindex'}]}
        />

        <Header />
        <LayoutMain>

            <LayoutPanel background="mer-bg--primary-cyan"                 
                            padding="mer-py-lg"
                            theme="mer-theme--cyan">
                <div className="container">
                    <div className="row mer-panel justify-content-center align-items-middle">
                        <div className="col-12 mer-text--align-center mer-text--size-lg">
                            <h1 className="my-0 mer-typography--headline2">Find out more about Kleenheat's</h1>
                        </div>
                    </div>
                </div>
            </LayoutPanel>

            <LayoutPanel background="mer-bg--gs-grey-light"      
                            border="mer-border--bottom"           
                            padding="mer-py-sm">
                <div className="container">
                    <div className="row mer-panel justify-content-center">
                        <div className="col-12 col-sm-10 col-lg-8 col-xl-7 mer-text--align-center">
                            <div className="content">
                                <h4>Standard Form Contract changes, effective 1 July 2024</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutPanel>

            <LayoutPanel background="mer-bg--gs-grey-lighter"
                            padding="mer-pt-de mer-pb-lg">
                <div className="container">
                    <div className="row mer-panel justify-content-center">
                        <div className="col-12 col-sm-10 col-lg-8 col-xl-7 content">
                            <p>The Code of Conduct for the Supply of Electricity to Small Use Customers 2022 (the Code) sets the standards and regulates the conduct of retailers and distributors who supply electricity to residential and small business customers.</p>
                            <p>Recently, the Economic Regulation Authority approved updates to the Code, and, as a result, we have revised our standard form contract to align with these changes. The updated terms will apply to your electricity plan with Kleenheat from <strong>1 July 2024</strong>.</p>
                            <p>Our updated standard form contract received approval from the Economic Regulation Authority on 28 June 2024.</p>
                            <p>Full versions of the new terms and conditions are available <a href="/wp-content/uploads/2024/06/ELE_SFC_TCs_Jul2024_FA.pdf" title="View new terms and conditions">here</a>. Alternatively, you can request a free paper copy by emailing us at&nbsp; 
                                <a href="mailto:electricity@kleenheat.com.au" title="Send us an email">electricity@kleenheat.com.au</a> or by calling us on&nbsp; 
                                <a href="tel:132180" title="Phone us">13 21 80</a>.
                            </p>
                            <p>Below is a summary of the key updates to our terms. While this summary is not exhaustive, please be assured that all changes are aimed at enhancing protections and ensuring high service standards for you:</p>
                            <h5>Residential Customers and non-Residential Customers:</h5>
                            <p>
                                <ul>
                                    <li><strong>Advance Notice of Price Changes</strong>: We will provide you with at least 5 business days' notice before changing your rates, fees, or charges.</li>
                                </ul>
                            </p>
                            <h5>Residential Customers:</h5>
                            <p>
                                <ul>
                                    <li><strong>Support for Residential Customers Impacted by Family and Domestic Violence</strong>: We will provide additional privacy protections to residential customers who are impacted by family and domestic violence, including offering secure communication channels and prioritising agreed-upon methods of communication. We will not disconnect a residential customer impacted by family and domestic violence for a period of at least nine months, and during this period and beyond, we will continually assess their situation to consider the implications of debt recovery and disconnection due to non-payment.</li>

                                    <li><strong>Extended Payment Assistance</strong>: We will offer payment assistance options, such as extensions and payment plans, to all residential customers upon request, with certain limitations in place. We will seek consent before modifying payment plans, and all residential customers have the right to request a review of their plan.</li>

                                    <li><strong>Minimum debt before disconnection</strong>: We will not disconnect service for residential customers with outstanding bills of less than $300, provided they have contacted us and agreed to a plan for paying the amount owed.</li>                                    
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </LayoutPanel>               

        </LayoutMain>
        <Footer />
    </>
    );
}

export default ContractUpdate;
